import {emptyTask} from "@co-common-libs/resources";
import {isDevelopment} from "@co-common-libs/utils";
import {getSettingsEntryLookupByIdentifier} from "@co-frontend-libs/redux";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SettingValueDisplayProps} from "../types";
import {taskCopyExcludes} from "./task-copy-fields-dialog";

type TaskCopyFieldsMember = keyof typeof emptyTask;
type TaskCopyFields = {[member in TaskCopyFieldsMember]: boolean};

export function DisplayTaskCopyFields(props: SettingValueDisplayProps): React.JSX.Element {
  const {settingID} = props;

  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const sentry = getFrontendSentry();
  const taskCopyFields: TaskCopyFields = useMemo(() => {
    const data: TaskCopyFieldsMember[] = settingEntry?.data || props.settingMetaData.schema.default;
    const newTaskCopyFields: Partial<TaskCopyFields> = {};
    const taskKeys = Object.keys(emptyTask) as TaskCopyFieldsMember[];
    data.forEach((key) => {
      if (!taskKeys.includes(key) || taskCopyExcludes.includes(key)) {
        if (isDevelopment()) {
          // eslint-disable-next-line no-console
          console.error(`'${key}' not in allowed emptyTask members`);
        }
        sentry.captureMessage(`'${key}' not in allowed emptyTask members`);
      }
    });
    taskKeys.sort();
    taskKeys.forEach((key) => {
      if (!taskCopyExcludes.includes(key)) {
        newTaskCopyFields[key] = data.includes(key);
      }
    });
    return newTaskCopyFields as TaskCopyFields;
  }, [settingEntry?.data, props.settingMetaData.schema.default, sentry]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{width: 400}}>
            <FormattedMessage defaultMessage="Navn" id="system-setup.dialog-title.name" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Kopieres" id="system-setup.copy" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(taskCopyFields).map(([field, enabled]) => {
          return (
            <TableRow key={field}>
              <TableCell style={{width: 400}}>{field}</TableCell>
              <TableCell>
                <Checkbox checked={enabled} disabled />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

import type {
  AuthorizationStatus as BackgroundGeolocationAuthorizationStatus,
  Config as BackgroundGeolocationConfig,
  default as BackgroundGeolocationPlugin,
  State as BackgroundGeolocationState,
} from "cordova-background-geolocation-lt";
import {TimerStart, urlToId, UserUrl} from "@co-common-libs/resources";
import {isDevelopment} from "@co-common-libs/utils";
import {
  getCurrentUserActiveTimerStartArray,
  getCurrentUserProfile,
  getCustomerSettings,
  getTimerLookup,
  getToken,
} from "@co-frontend-libs/redux";
import {useDeviceConfig} from "app-utils";
import {getGeolocationTracker, globalConfig} from "frontend-global-config";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {IntlShape, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {RequestGPSPermissionDialog} from "./request-gps-permission-dialog";

declare const BackgroundGeolocation: typeof BackgroundGeolocationPlugin;

let pluginInitialized = false;

let initialPermissionRequest = false;

let disallowed = false;

function readyBackgroundGeolocation(
  intl: IntlShape,
  onDisallow: () => void,
): Promise<BackgroundGeolocationState> {
  if (isDevelopment()) {
    /* eslint-disable no-console */
    BackgroundGeolocation.onLocation(
      (location) => {
        console.log("[location] ", location);
      },
      (error) => {
        console.log("[location] ERROR: ", error);
      },
    );
    BackgroundGeolocation.onMotionChange((location) => {
      console.log("[motionchange] ", location);
    });
    BackgroundGeolocation.onHttp((response) => {
      console.log("[http] ", response);
    });
    BackgroundGeolocation.onActivityChange((activityEvent) => {
      console.log("[activitychange] ", activityEvent);
    });
    BackgroundGeolocation.onEnabledChange((isEnabled) => {
      console.log("[onEnabledChanged] isEnabled? ", isEnabled);
    });
    BackgroundGeolocation.onHeartbeat((event) => {
      console.log("[onHeartbeat] ", event);
    });
    BackgroundGeolocation.onConnectivityChange((event) => {
      console.log("[onConnectivityChange] ", event);
    });
    BackgroundGeolocation.onPowerSaveChange((isPowerSaveMode) => {
      console.log("[onPowerSaveChange] ", isPowerSaveMode);
    });
    /* eslint-enable no-console */
  }

  BackgroundGeolocation.onProviderChange((providerEvent) => {
    if (isDevelopment()) {
      // eslint-disable-next-line no-console
      console.log("[providerchange] ", providerEvent);
    }
    if (
      providerEvent.status === BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED &&
      (pluginInitialized || !initialPermissionRequest)
    ) {
      // ignore "no permission" event on initialisation if/when
      // we've just gotten OK from RequestGPSPermissionDialog
      onDisallow();
    }
    // https://transistorsoft.github.io/cordova-background-geolocation-lt/classes/backgroundgeolocation.html#onproviderchange
    // `onProviderChange` is triggered immediately after `ready`;
    // so set `pluginInitialized` and `initialPermissionRequest` here,
    // *after* processing that initial `onProviderChange`
    if (!pluginInitialized) {
      pluginInitialized = true;
      initialPermissionRequest = false;
    }
  });
  const config: BackgroundGeolocationConfig = {
    activityType: BackgroundGeolocation.ACTIVITY_TYPE_OTHER,
    backgroundPermissionRationale: {
      message: intl.formatMessage({
        defaultMessage:
          "Geolokation (GPS) anvendes af CustomOffice appen, også når appen er i baggrunden, til dokumentation og kvalitetssikring under udførsel af arbejde. Den mest anvendte funktion er registrering af position og tid ved start af arbejdet - og løbende gennem udførelse af arbejdsopgaven. Det er vigtigt at kunne dokumentere hvor og hvornår man har udført den specifikke arbejdsopgave.",
      }),
      positiveAction: "Giv adgang til GPS i baggrunden",
      title: intl.formatMessage({
        defaultMessage: "CustomOffice har behov for adgang til GPS",
      }),
    },
    batchSync: true,
    debug: isDevelopment(),
    desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_MEDIUM,
    disableLocationAuthorizationAlert: true,
    locationAuthorizationRequest: "Always",
    maxBatchSize: 20,
    maxDaysToPersist: 10,
    notification: {
      text: intl.formatMessage({
        defaultMessage: "Sporer GPS-position",
      }),
      title: intl.formatMessage({defaultMessage: "CustomOffice"}),
    },
    reset: true,
  };
  return BackgroundGeolocation.ready(config);
}

async function updateGeolocationTracking(
  intl: IntlShape,
  timerStart: TimerStart | null,
  userUrl: UserUrl | null,
  authToken: string | null,
  enabled: boolean,
  onDisallow: () => void,
  pluginHasStoredConfiguration: boolean | undefined,
  setPluginHasStoredConfiguration: (value: true) => void,
): Promise<void> {
  if (!enabled && !pluginHasStoredConfiguration) {
    return;
  }
  let state: BackgroundGeolocationState;
  if (pluginInitialized) {
    state = await BackgroundGeolocation.getState();
  } else {
    state = await readyBackgroundGeolocation(intl, onDisallow);
  }
  if (!pluginHasStoredConfiguration) {
    setPluginHasStoredConfiguration(true);
    if (disallowed) {
      return;
    }
    // trigger request for GPS UI...
    let status: BackgroundGeolocationAuthorizationStatus;
    try {
      status = await BackgroundGeolocation.requestPermission();
    } catch (_error) {
      onDisallow();
      return;
    }
    if (status === BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED) {
      onDisallow();
      return;
    }
  }
  if (timerStart && userUrl && authToken && enabled && !disallowed) {
    // Should run/record positions
    const syncOptions = {
      extras: {
        taskId: urlToId(timerStart.task),
        timerStart: {
          deviceTimestamp: timerStart.deviceTimestamp,
          deviceUuid: timerStart.deviceUuid,
          employeeId: urlToId(timerStart.employee),
          id: urlToId(timerStart.url),
          taskId: urlToId(timerStart.task),
          timerId: timerStart.timer ? urlToId(timerStart.timer) : null,
        },
        userId: urlToId(userUrl),
      },
      headers: {
        Authorization: `Token ${authToken}`,
      },
      url: `${globalConfig.baseURL}/api/background_position/`,
    };
    if (!_.isMatch(state, syncOptions)) {
      state = await BackgroundGeolocation.setConfig(syncOptions);
    }
    if (!state.enabled) {
      try {
        state = await BackgroundGeolocation.start();
      } catch (_error) {
        // Permission denied...?
        onDisallow();
        return;
      }
    }
    if ((window as any).device?.isVirtual && !state.isMoving) {
      // hack for test on Android emulator:
      // BackgroundGeolocation checks accelerometer for movement;
      // pretend we're moving anyway
      await BackgroundGeolocation.changePace(true);
    }
  } else {
    // Should *not* run/record positions
    if (state.enabled) {
      await BackgroundGeolocation.stop();
    }
  }
}

export const BackgroundGeolocationManager = (): React.JSX.Element => {
  const intl = useIntl();

  const customerSettings = useSelector(getCustomerSettings);
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const authToken = useSelector(getToken);
  const currentUserActiveTimerStartArray = useSelector(getCurrentUserActiveTimerStartArray);
  const timerLookup = useSelector(getTimerLookup);

  const [trackingPermissionGranted, setTrackingPermissionGranted] = useDeviceConfig<boolean>(
    "trackingPermissionGranted",
  );

  const [pluginHasStoredConfiguration, setPluginHasStoredConfiguration] = useDeviceConfig<boolean>(
    "backgroundGeolocationConfigured",
  );

  const [dialogOpen, setDialogOpen] = useState(false);

  const lastActiveTimerStart = useMemo(
    () => _.last(currentUserActiveTimerStartArray),
    [currentUserActiveTimerStartArray],
  );

  const currentlyUpdatingTrackingStateRef = useRef(false);

  const handleAllowGPS = useCallback(async () => {
    setDialogOpen(false);
    setTrackingPermissionGranted(true);
    // legacy code; initialises and implicitly asks permission if
    // app version matches/needs that version...
    await getGeolocationTracker();
  }, [setTrackingPermissionGranted]);

  const handleDisallowGPS = useCallback(() => {
    disallowed = true;
    initialPermissionRequest = false;
    setDialogOpen(false);
    setTrackingPermissionGranted(false);
  }, [setDialogOpen, setTrackingPermissionGranted]);

  const handleUpdateGeolocationTracking = useCallback(async () => {
    if (currentlyUpdatingTrackingStateRef.current) {
      return;
    }
    currentlyUpdatingTrackingStateRef.current = true;
    // NOTE:
    // while `backgroundPositionInterval` can be translated to option
    // `locationUpdateInterval` for Android, this does not work for iOS,
    // and it requires us to disable `distanceFilter` -- so just use it
    // as a boolean flag instead...
    const enabled =
      customerSettings.geolocation.enabled &&
      trackingPermissionGranted === true &&
      customerSettings.backgroundPositionInterval > 0;
    if (
      customerSettings.geolocation.enabled &&
      lastActiveTimerStart &&
      lastActiveTimerStart.timer &&
      !timerLookup(lastActiveTimerStart.timer)?.isBreak
    ) {
      await updateGeolocationTracking(
        intl,
        lastActiveTimerStart,
        currentUserProfile?.user || null,
        authToken,
        enabled,
        handleDisallowGPS,
        pluginHasStoredConfiguration,
        setPluginHasStoredConfiguration,
      );
    } else {
      await updateGeolocationTracking(
        intl,
        null,
        null,
        authToken,
        enabled,
        handleDisallowGPS,
        pluginHasStoredConfiguration,
        setPluginHasStoredConfiguration,
      );
    }
    currentlyUpdatingTrackingStateRef.current = false;
  }, [
    authToken,
    pluginHasStoredConfiguration,
    currentUserProfile?.user,
    customerSettings.backgroundPositionInterval,
    customerSettings.geolocation.enabled,
    handleDisallowGPS,
    intl,
    lastActiveTimerStart,
    setPluginHasStoredConfiguration,
    timerLookup,
    trackingPermissionGranted,
  ]);

  // Intentionally rerun on changes to depemdencies of handleUpdateGeolocationTracking
  useEffect(() => {
    const appVersion = (window as any).APP_VERSION;
    const requiredAppVersion =
      globalConfig.cordova.pluginsRequiredAppVersion["cordova-background-geolocation-lt"];
    if (
      typeof cordova !== "undefined" &&
      typeof BackgroundGeolocation !== "undefined" &&
      appVersion &&
      appVersion >= requiredAppVersion
    ) {
      handleUpdateGeolocationTracking();
      document.addEventListener("resume", handleUpdateGeolocationTracking, false);
      return () => {
        document.removeEventListener("resume", handleUpdateGeolocationTracking, false);
      };
    } else {
      return undefined;
    }
  }, [handleUpdateGeolocationTracking]);

  useEffect(() => {
    const autoOpenDialog = !!(
      customerSettings.geolocation.enabled &&
      typeof cordova !== "undefined" &&
      (trackingPermissionGranted === undefined ||
        (!trackingPermissionGranted && currentUserProfile?.requireGps))
    );
    if (!dialogOpen && autoOpenDialog) {
      if (!pluginInitialized) {
        initialPermissionRequest = true;
      }
      setDialogOpen(true);
    }
  }, [
    currentUserProfile?.requireGps,
    customerSettings.geolocation.enabled,
    dialogOpen,
    trackingPermissionGranted,
  ]);

  return (
    <RequestGPSPermissionDialog
      onAllowGPS={handleAllowGPS}
      onDisallowGPS={handleDisallowGPS}
      open={dialogOpen}
    />
  );
};

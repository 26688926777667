import {Config, loadCheckConfig, unsafeLoadConfig} from "@co-common-libs/config";
import {ResourceName, resourceNames, SettingEntry} from "@co-common-libs/resources";
import {isProduction} from "@co-common-libs/utils";
import {Query} from "@co-frontend-libs/db-resources";
import {createSelector} from "reselect";
import {QueryQueryStateStruct, ResourceInstanceRecords, ResourcesState} from "../types";
import {
  combineResourceOfflineCommittingData,
  partialEntriesForEach,
  partialValuesForEach,
} from "../utils";
import {getSettingEntryArray} from "./resources";
import {combineOfflineData, getCommittingPerResource} from "./resources-helpers";

export {getData} from "./get-data";
export * from "./resources";
export {getCommitQueue, getCommitQueueLength} from "./resources-helpers";

function queryQueryStateStructMapToQueryCheckFnStructArray(
  record: Readonly<Partial<Record<string, QueryQueryStateStruct>>>,
): readonly Query[] {
  const result: Query[] = [];
  partialValuesForEach(record, (entry) => {
    result.push(entry.query);
  });
  return result;
}

export const getPersistedQueriesSyncedState = (state: {
  resources: ResourcesState;
}): Readonly<Partial<Record<string, QueryQueryStateStruct>>> => state.resources.persistedQueries;

export const getPersistedQueries: (state: {resources: ResourcesState}) => readonly Query[] =
  createSelector(getPersistedQueriesSyncedState, queryQueryStateStructMapToQueryCheckFnStructArray);

export const getTemporaryQueriesSyncedState = (state: {
  resources: ResourcesState;
}): Readonly<Partial<Record<string, QueryQueryStateStruct>>> => state.resources.temporaryQueries;

export const getTemporaryQueries: (state: {resources: ResourcesState}) => readonly Query[] =
  createSelector(getTemporaryQueriesSyncedState, queryQueryStateStructMapToQueryCheckFnStructArray);

export const getQueriesSyncedState: (state: {
  resources: ResourcesState;
}) => Readonly<Partial<Record<string, QueryQueryStateStruct>>> = createSelector(
  getPersistedQueriesSyncedState,
  getTemporaryQueriesSyncedState,
  (persistedQueries, temporaryQueries) => ({
    ...temporaryQueries,
    ...persistedQueries,
  }),
);

export const getActiveQueries: (state: {
  resources: ResourcesState;
}) => Readonly<Partial<Record<string, Query>>> = createSelector(
  getQueriesSyncedState,
  (syncedstateRecord) => {
    const result: Partial<Record<string, Query>> = {};
    partialEntriesForEach(syncedstateRecord, (keyString, {query, queryState}) => {
      if (queryState.currentlyFullFetching) {
        result[keyString] = query;
      }
    });
    return result;
  },
);

export const getCurrentlyFetchingChanges = (state: {resources: ResourcesState}): boolean =>
  state.resources.currentlyFetchingChanges;

export const getLastFetchChangesTimestamp = (state: {resources: ResourcesState}): string | null =>
  state.resources.lastFetchChangesTimestamp;

export const getLastFetchChangesErrorTimestamp = (state: {
  resources: ResourcesState;
}): string | null => state.resources.lastFetchChangesErrorTimestamp;

export const getFetchByRelationActive = (state: {
  resources: ResourcesState;
}): ResourcesState["fetchByRelationActive"] => state.resources.fetchByRelationActive;

export const getFetchByIdActive = (state: {
  resources: ResourcesState;
}): ResourcesState["fetchByIdActive"] => state.resources.fetchByIdActive;

export const getCurrentlyFetching: (state: {resources: ResourcesState}) => boolean = createSelector(
  getActiveQueries,
  getCurrentlyFetchingChanges,
  getFetchByRelationActive,
  getFetchByIdActive,
  (activeQueries, currentlyFetchingChanges, fetchByRelationActive, fetchByIdActive) =>
    currentlyFetchingChanges ||
    !!Object.keys(activeQueries).length ||
    !!Object.keys(fetchByRelationActive).length ||
    !!Object.keys(fetchByIdActive).length,
);

export const getPersistedFetchByID = (state: {
  resources: ResourcesState;
}): ResourcesState["persistedFetchByID"] => state.resources.persistedFetchByID;

export const getAllPersistedResourceData = (state: {
  resources: ResourcesState;
}): ResourceInstanceRecords => state.resources.persistedData;

export const getAllTemporaryResourceData = (state: {
  resources: ResourcesState;
}): ResourceInstanceRecords => state.resources.temporaryData;

export const getAllResourcesData: (state: {resources: ResourcesState}) => ResourceInstanceRecords =
  createSelector(
    getAllPersistedResourceData,
    getAllTemporaryResourceData,
    getCommittingPerResource,
    (persistedData, temporaryData, committing) => {
      const result = Object.fromEntries(
        resourceNames.map((resourceName) => {
          const value = combineResourceOfflineCommittingData(
            combineOfflineData(
              persistedData[resourceName] || {},
              temporaryData[resourceName] || {},
            ),
            committing[resourceName],
          );
          return [resourceName, value];
        }),
      );
      return result as ResourceInstanceRecords;
    },
  );

export const getOfflineLoaded = (state: {resources: ResourcesState}): boolean =>
  state.resources.offlineLoaded;

export const getCommitLoaded = (state: {resources: ResourcesState}): boolean =>
  state.resources.commitLoaded;

export const getSyncedState = (
  resourceName: ResourceName,
  state: {resources: ResourcesState},
): ReadonlyMap<string, QueryQueryStateStruct> => {
  console.assert(state, "getSyncedState() called without state parameter");
  const result = new Map<string, QueryQueryStateStruct>();
  const syncedstateMap = getQueriesSyncedState(state);
  partialEntriesForEach(syncedstateMap, (keyString, entry) => {
    if (entry.query.resourceName === resourceName) {
      result.set(keyString, entry);
    }
  });
  return result;
};

export const getFetchingQueries = (
  resourceName: ResourceName,
  state: {resources: ResourcesState},
): ReadonlyMap<string, Query> => {
  const result = new Map<string, Query>();
  const syncedState = getSyncedState(resourceName, state);
  syncedState.forEach(({query, queryState}, keyString) => {
    if (queryState.currentlyFullFetching) {
      result.set(keyString, query);
    }
  });
  return result;
};

export const getFailedQueries = (
  resourceName: ResourceName,
  state: {resources: ResourcesState},
): ReadonlyMap<string, Query> => {
  const result = new Map<string, Query>();
  const syncedState = getSyncedState(resourceName, state);
  syncedState.forEach(({query, queryState}, keyString) => {
    if (queryState.lastErrorTimestamp && !queryState.currentlyFullFetching) {
      result.set(keyString, query);
    }
  });
  return result;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _getEverything = (state: {resources: ResourcesState}): ResourcesState =>
  state.resources;

export const getLocalDbUpdateError = (state: {resources: ResourcesState}): Error | null =>
  state.resources.localDBError;

function configFromSettingEntryArray(settingEntryArray: readonly Readonly<SettingEntry>[]): Config {
  if (settingEntryArray.length) {
    const loaded: {[key: string]: any} = {};
    settingEntryArray.forEach(({data, key}) => {
      loaded[key] = data;
    });
    if (isProduction() || process.env.NODE_ENV === "test") {
      return unsafeLoadConfig(loaded);
    } else {
      return loadCheckConfig(loaded);
    }
  } else {
    return unsafeLoadConfig({});
  }
}

export const getCustomerSettings: (state: {resources: ResourcesState}) => Config = createSelector(
  getSettingEntryArray,
  configFromSettingEntryArray,
);

import _ from "lodash";

export const simpleComparator = (valueA: unknown, valueB: unknown): number => {
  if (typeof valueA === "number" && typeof valueB === "number") {
    return valueA - valueB;
  }
  const comparisonValueA = typeof valueA === "string" ? valueA : `${valueA}`;
  const comparisonValueB = typeof valueB === "string" ? valueB : `${valueB}`;
  if (comparisonValueA < comparisonValueB) {
    return -1;
  } else if (comparisonValueB < comparisonValueA) {
    return 1;
  } else {
    return 0;
  }
};

export const identifierPartsComparator = (
  valueA: readonly (number | string)[],
  valueB: readonly (number | string)[],
): number => {
  const partCount = Math.min(valueA.length, valueB.length);
  for (let i = 0; i < partCount; i += 1) {
    const partResult = simpleComparator(valueA[i], valueB[i]);
    if (partResult !== 0) {
      return partResult;
    }
  }
  return valueA.length - valueB.length;
};

export const identifierToComparisonParts = (inputValue: string): (number | string)[] => {
  if (!inputValue) {
    return [];
  }
  const value = inputValue.toLowerCase();
  const parts: any[] = [];
  let part = "";
  let currentNumeric = value[0] <= "9" && value[0] >= "0";
  for (let pos = 0; pos < value.length; pos += 1) {
    const chr = value[pos];
    const chrIsNumeric = chr <= "9" && chr >= "0";
    if (chrIsNumeric === currentNumeric) {
      part += chr;
    } else {
      const partResult = currentNumeric ? parseInt(part) : part;
      parts.push(partResult);
      part = chr;
      currentNumeric = !currentNumeric;
    }
  }
  const partResult = currentNumeric ? parseInt(part) : part;
  parts.push(partResult);
  return parts;
};

export const identifierComparator = (valueA: string, valueB: string): number => {
  const valueAParts = identifierToComparisonParts(valueA);
  const valueBParts = identifierToComparisonParts(valueB);
  const partsResult = identifierPartsComparator(valueAParts, valueBParts);
  if (partsResult !== 0) {
    return partsResult;
  } else {
    return simpleComparator(valueA, valueB);
  }
};

export const initialComparator = (valueA: string, valueB: string): number => {
  for (let i = 0; i < Math.min(valueA.length, valueB.length); i += 1) {
    const aChar = valueA.charAt(i);
    const bChar = valueB.charAt(i);
    const compareResult = aChar.localeCompare(bChar);
    if (compareResult !== 0) {
      return compareResult;
    }
  }
  return valueA.length - valueB.length;
};

function getOrderMember<T extends {readonly order: number}>(obj: T): number {
  return obj.order;
}

export function sortByOrderMember<T extends {readonly order: number}>(arr: readonly T[]): T[] {
  return _.sortBy(arr, getOrderMember);
}

function compareOrderMember<T extends {readonly order: number}>(a: T, b: T): number {
  return a.order - b.order;
}

export function valuesSortedByOrderMember<T extends {readonly order: number}>(data: {
  readonly [identifier: string]: T;
}): T[] {
  return Object.values(data).sort(compareOrderMember);
}

function compareEntryOrderMember<T extends {readonly order: number}>(
  a: [string, T],
  b: [string, T],
): number {
  return a[1].order - b[1].order;
}

export function entriesSortedByOrderMember<T extends {readonly order: number}>(data: {
  readonly [identifier: string]: T;
}): [string, T][] {
  return Object.entries(data).sort(compareEntryOrderMember);
}

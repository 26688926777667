import {
  LocationUrl,
  Machine,
  Order,
  PriceGroup,
  PriceItemUsesDict,
  ProductUsesDict,
  Project,
  Task,
  TaskUrl,
  UserProfile,
  WorkType,
} from "@co-common-libs/resources";
import {caseAccentInsensitiveCollator, notUndefined} from "@co-common-libs/utils";
import {
  actions,
  getCurrentRole,
  getExtendedCustomerSettings,
  getLocationLookup,
  getMachineLookup,
  getPriceGroupLookup,
  getReportingSpecificationArray,
  getReportingSpecificationLookup,
  getTaskArray,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {InvoiceLineTable, Linkify, useLocationSetters} from "app-components";
import {focusButton, getReferenceNumberLabel, WorkTypeChangeBlockedReason} from "app-utils";
import React, {useCallback, useRef} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {allowMachineUseForWorktype} from "../order-instance/task-instance";
import TaskEntry from "./task-entry";

export interface TaskAndData {
  readonly machineList: readonly Machine[];
  readonly machineOperatorProfile: UserProfile | undefined;
  readonly machinePriceGroups: readonly PriceGroup[];
  readonly priceGroup: PriceGroup | undefined;
  readonly project: Project | undefined;
  readonly task: Task;
  readonly workType: WorkType | undefined;
}

interface TaskTableProps {
  onRequestEstimatedTimeEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestMachineAdd: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onRequestMachineOperatorEdit: (taskURL: TaskUrl, button: HTMLButtonElement | null) => void;
  onTaskCopy: (taskURL: TaskUrl) => void;
  onWorkTypeChangeBlocked: (reason: WorkTypeChangeBlockedReason) => void;
  order: Order;
  taskAndDataArray: readonly TaskAndData[];
  tasksWhereMachineOperatorHasMultipleTasks: ReadonlySet<TaskUrl>;
  tasksWithAbsence: ReadonlySet<TaskUrl>;
  usesOrderApproval: boolean;
}

export function TaskTable({
  onRequestEstimatedTimeEdit,
  onRequestMachineAdd,
  onRequestMachineOperatorEdit,
  onTaskCopy,
  onWorkTypeChangeBlocked,
  order,
  taskAndDataArray,
  tasksWhereMachineOperatorHasMultipleTasks,
  tasksWithAbsence,
  usesOrderApproval,
}: TaskTableProps): React.JSX.Element {
  const {formatMessage} = useIntl();
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const timerLookup = useSelector(getTimerLookup);
  const role = useSelector(getCurrentRole);
  const taskArray = useSelector(getTaskArray);
  const machineLookup = useSelector(getMachineLookup);
  const timerArray = useSelector(getTimerArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const locationLookup = useSelector(getLocationLookup);
  const timerStartArray = useSelector(getTimerStartArray);
  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const currentTask = useRef<TaskUrl | null>(null);
  const dispatch = useDispatch();

  const {
    transferTask: {rules: transferTaskRules},
  } = customerSettings;

  const go = useCallback(
    (...props: Parameters<typeof actions.go>) => dispatch(actions.go(...props)),
    [dispatch],
  );
  const update = useCallback(
    (...props: Parameters<typeof actions.update>) => dispatch(actions.update(...props)),
    [dispatch],
  );

  const handleTaskWorkplaceChanged = useCallback(
    (locationUrl: LocationUrl, taskUrl: TaskUrl) => {
      dispatch(actions.update(taskUrl, [{member: "relatedWorkplace", value: locationUrl}]));
    },

    [dispatch],
  );

  const onLocationSelected = useCallback(
    (locationUrl: LocationUrl | null): void => {
      if (locationUrl && currentTask.current) {
        handleTaskWorkplaceChanged(locationUrl, currentTask.current);
      }
      currentTask.current = null;
    },
    [handleTaskWorkplaceChanged, currentTask],
  );

  const [selectLocationDialog, createLocationDialog, openWorkplaceEdit] = useLocationSetters({
    customerUrl: order.customer,
    includeLogOnlyLocations: false,
    includeWorkplaceOnlyLocations: true,
    onLocationChanged: onLocationSelected,
    titleVariant: "WORKPLACE",
  });

  const onRequestWorkplaceEdit = useCallback(
    async (taskUrl: TaskUrl, button: HTMLButtonElement | null) => {
      currentTask.current = taskUrl;
      await openWorkplaceEdit();
      focusButton(button);
    },
    [openWorkplaceEdit],
  );

  const isManager = role && role.manager;
  let taskTableHeaders: React.JSX.Element[];
  const workTypeCell = (
    <TableCell key="workType">
      <FormattedMessage defaultMessage="Område" />
    </TableCell>
  );
  let projectCell;
  if (customerSettings.enableProjects) {
    projectCell = (
      <TableCell key="project">
        {customerSettings.projectLabelVariant === "PROJECT" ? (
          <FormattedMessage defaultMessage="Projekt" />
        ) : (
          <FormattedMessage defaultMessage="Sag" />
        )}
      </TableCell>
    );
  }
  let priceGroupCell;
  if (customerSettings.orderEntryShowPriceGroup) {
    priceGroupCell = (
      <TableCell key="pricegroup">
        <FormattedMessage defaultMessage="Variant" />
      </TableCell>
    );
  }
  let workplaceCell;
  if (customerSettings.orderEntryShowWorkPlace) {
    workplaceCell = (
      <TableCell key="workplace">
        <FormattedMessage defaultMessage="Arbejdssted" />
      </TableCell>
    );
  }
  let pickupDeliveryCells;
  if (customerSettings.showLogPickupAndDeliveryColumnsOnOrderEntryTaskList) {
    pickupDeliveryCells = (
      <React.Fragment key="pickup-delivery">
        <TableCell>
          <FormattedMessage defaultMessage="Afh., log" />
        </TableCell>
        <TableCell key="workplace">
          <FormattedMessage defaultMessage="Lev., log" />
        </TableCell>
      </React.Fragment>
    );
  }
  let timeCell;
  if (customerSettings.showTimeColumnOnOrderEntryTaskList) {
    timeCell = (
      <TableCell key="time">
        <FormattedMessage defaultMessage="Klokkeslæt" />
      </TableCell>
    );
  }
  let refNumberCell;
  if (customerSettings.showTaskRefColumnOnOrderEntryTaskList) {
    refNumberCell = (
      <TableCell key="refnumber">
        {getReferenceNumberLabel(
          customerSettings.enableTaskReferenceNumber,
          customerSettings.taskReferenceNumberLabel,
          false,
          null,
          formatMessage,
        )}
      </TableCell>
    );
  }
  let invoiceNoteCell;
  if (customerSettings.showTaskInvoiceNoteColumnOnOrderEntryTaskList) {
    invoiceNoteCell = (
      <TableCell key="intfaknote">
        <FormattedMessage defaultMessage="Int. fak. note" />
      </TableCell>
    );
  }
  let fieldsCell;
  if (customerSettings.showFieldsColumnOnOrderEntryTaskList) {
    fieldsCell = (
      <TableCell key="fields">
        <FormattedMessage defaultMessage="Marker" />
      </TableCell>
    );
  }
  const machineOperatorCell = (
    <TableCell key="machineoperator">
      {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
        <FormattedMessage defaultMessage="Maskinfører" />
      ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
        <FormattedMessage defaultMessage="Medarbejder" />
      ) : (
        <FormattedMessage defaultMessage="Chauffør" />
      )}
    </TableCell>
  );
  let estimatedTimeCell;
  if (customerSettings.enableTaskEstimation) {
    estimatedTimeCell = (
      <TableCell key="estimatedtime">
        <FormattedMessage defaultMessage="Estimeret tid" />
      </TableCell>
    );
  }
  const machinesCell = (
    <TableCell key="machines">
      {customerSettings.machineLabelVariant === "MACHINE" ? (
        <FormattedMessage defaultMessage="Maskiner" />
      ) : (
        <FormattedMessage defaultMessage="Køretøjer" />
      )}
    </TableCell>
  );
  const dateCell = (
    <TableCell key="date">
      <FormattedMessage defaultMessage="Dato" />
    </TableCell>
  );
  const detailsCell = (
    <TableCell
      key="details"
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        width: 48,
      }}
    />
  );
  let taskCopyCell;
  if (customerSettings.adminCanCreateCustomerTask) {
    taskCopyCell = (
      <TableCell
        key="copy"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 48,
        }}
      />
    );
  }
  let taskMoveCell;
  if (customerSettings.adminCanCreateCustomerTask) {
    taskMoveCell = (
      <TableCell
        key="taskMove"
        style={{
          paddingLeft: 0,
          paddingRight: 0,
          width: 48,
        }}
      />
    );
  }
  const goToTaskCell = (
    <TableCell
      key="go-to-task"
      style={{
        paddingLeft: 0,
        paddingRight: 0,
        width: 48,
      }}
    />
  );
  let statusCell;
  if (isManager) {
    statusCell = (
      <TableCell
        key="status"
        style={{
          paddingLeft: 12,
          paddingRight: 12,
          width: 48,
        }}
      />
    );
  }
  if (customerSettings.noExternalTaskWorkType) {
    taskTableHeaders = [
      machinesCell,
      priceGroupCell,
      workplaceCell,
      pickupDeliveryCells,
      machineOperatorCell,
      projectCell,
      refNumberCell,
      invoiceNoteCell,
      fieldsCell,
      estimatedTimeCell,
      dateCell,
      timeCell,
      statusCell,
      goToTaskCell,
      taskCopyCell,
      taskMoveCell,
      detailsCell,
    ].filter(notUndefined);
  } else {
    // has task worktype, does not have order worktype
    taskTableHeaders = [
      workTypeCell,
      priceGroupCell,
      workplaceCell,
      pickupDeliveryCells,
      machineOperatorCell,
      projectCell,
      refNumberCell,
      invoiceNoteCell,
      fieldsCell,
      estimatedTimeCell,
      machinesCell,
      dateCell,
      timeCell,
      statusCell,
      goToTaskCell,
      taskCopyCell,
      taskMoveCell,
      detailsCell,
    ].filter(notUndefined);
  }
  const taskEntryArray: React.JSX.Element[] = [];
  let colCount = 0;
  for (let i = 0; i < taskTableHeaders.length; i += 1) {
    if (taskTableHeaders[i]) {
      colCount += 1;
    }
  }

  taskAndDataArray.forEach((taskAndData, index) => {
    const {machineList, machineOperatorProfile, machinePriceGroups, priceGroup, project, workType} =
      taskAndData;

    const striped = transferTaskRules.requireOrderApproval && index % 2 !== 0;
    const taskURL = taskAndData.task.url;
    const machineOperatorAbsent = tasksWithAbsence.has(taskURL);
    const allowMachineUse = allowMachineUseForWorktype(workType);

    const machineOperatorHasMultipleTasks = tasksWhereMachineOperatorHasMultipleTasks.has(taskURL);

    taskEntryArray.push(
      <TaskEntry
        allowMachineUse={allowMachineUse}
        allowProjectChange={!!order.customer}
        currentRole={role}
        customerSettings={customerSettings}
        customerURL={order.customer}
        go={go}
        key={taskURL}
        locationLookup={locationLookup}
        machineList={machineList}
        machineLookup={machineLookup}
        machineOperatorAbsent={machineOperatorAbsent}
        machineOperatorHasMultipleTasks={machineOperatorHasMultipleTasks}
        machineOperatorProfile={machineOperatorProfile}
        machinePriceGroups={machinePriceGroups}
        onRequestEstimatedTimeEdit={onRequestEstimatedTimeEdit}
        onRequestMachineAdd={onRequestMachineAdd}
        onRequestMachineOperatorEdit={onRequestMachineOperatorEdit}
        onRequestWorkplaceEdit={onRequestWorkplaceEdit}
        onTaskCopy={onTaskCopy}
        onWorkTypeChangeBlocked={onWorkTypeChangeBlocked}
        order={order}
        priceGroup={priceGroup}
        priceGroupLookup={priceGroupLookup}
        project={project}
        reportingSpecificationArray={reportingSpecificationArray}
        reportingSpecificationLookup={reportingSpecificationLookup}
        striped={striped}
        task={taskAndData.task}
        taskArray={taskArray}
        timerArray={timerArray}
        timerStartArray={timerStartArray}
        update={update}
        workType={workType}
        workTypeLookup={workTypeLookup}
      />,
    );
    const {invoiceNote, notesFromMachineOperator, notesFromManager} = taskAndData.task;

    const timerNotes = taskAndData.task.timernotesSet
      .map((timerNote) => {
        const timer = timerLookup(timerNote.timer);
        if (timer) {
          return `${timer.label}: ${timerNote.notes}`;
        } else {
          return undefined;
        }
      })
      .filter(notUndefined)
      .sort(caseAccentInsensitiveCollator.compare);

    const cellStyle: React.CSSProperties = {
      paddingLeft: 48,
    };
    if (striped) {
      cellStyle.backgroundColor = "rgba(129, 212, 249, 0.4)";
    }

    if (
      customerSettings.showTaskNotesOnOrderEntryPage &&
      (invoiceNote || notesFromMachineOperator || notesFromManager || timerNotes.length)
    ) {
      taskEntryArray.push(
        <tr key={`${taskURL}-notes`}>
          <td colSpan={colCount} style={cellStyle}>
            <Linkify>
              {notesFromMachineOperator || timerNotes.length ? (
                <p style={{whiteSpace: "pre-line"}}>
                  <strong>
                    {customerSettings.employeeLabelVariant === "MACHINEOPERATOR" ? (
                      <FormattedMessage defaultMessage="Maskinfører:" />
                    ) : customerSettings.employeeLabelVariant === "EMPLOYEE" ? (
                      <FormattedMessage defaultMessage="Medarbejder:" />
                    ) : (
                      <FormattedMessage defaultMessage="Chauffør:" />
                    )}
                  </strong>
                  {notesFromMachineOperator ? <br /> : null}
                  {notesFromMachineOperator}
                  {timerNotes.map((timernote, i) => (
                    <span key={i} style={{whiteSpace: "pre-line"}}>
                      <br />
                      {timernote}
                    </span>
                  ))}
                </p>
              ) : null}
              {notesFromManager ? (
                <p style={{whiteSpace: "pre-line"}}>
                  <strong>
                    <FormattedMessage defaultMessage="Administration:" />
                  </strong>
                  <br />
                  {notesFromManager}
                </p>
              ) : null}
              {invoiceNote ? (
                <p style={{whiteSpace: "pre-line"}}>
                  <strong>
                    <FormattedMessage defaultMessage="Fakturering:" />
                  </strong>
                  <br />
                  {invoiceNote}
                </p>
              ) : null}
            </Linkify>
          </td>
        </tr>,
      );
    }

    if (usesOrderApproval && taskAndData.task.completed) {
      const editDisabled = order.validatedAndRecorded || taskAndData.task.recordedInC5;
      taskEntryArray.push(
        <tr key={`${taskAndData.task.url}-values`}>
          <td colSpan={colCount} style={cellStyle}>
            <InvoiceLineTable
              editDisabled={!!editDisabled}
              striped={false}
              task={
                taskAndData.task as Task & {
                  readonly priceItemUses: PriceItemUsesDict;
                  readonly productUses: ProductUsesDict;
                }
              }
            />
          </td>
        </tr>,
      );
    } else if (customerSettings.enableInvoiceCorrections && taskAndData.task.completed) {
      const editDisabled = taskAndData.task.validatedAndRecorded || taskAndData.task.reportApproved;
      taskEntryArray.push(
        <tr key={`${taskAndData.task.url}-values`}>
          <td colSpan={colCount} style={cellStyle}>
            <InvoiceLineTable
              editDisabled={!!editDisabled}
              striped={false}
              task={
                taskAndData.task as Task & {
                  readonly priceItemUses: PriceItemUsesDict;
                  readonly productUses: ProductUsesDict;
                }
              }
            />
          </td>
        </tr>,
      );
    }
  });
  return (
    <Table>
      <TableHead>
        <TableRow>{taskTableHeaders}</TableRow>
      </TableHead>
      <TableBody>{taskEntryArray}</TableBody>
      {selectLocationDialog}
      {createLocationDialog}
    </Table>
  );
}

import {frontendVersion} from "@co-common-libs/frontend-version";
import {isProduction} from "@co-common-libs/utils";
import {getFrontendSentry} from "@co-frontend-libs/utils";
import * as Sentry from "@sentry/browser";
import bowser from "bowser";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {createRoot} from "react-dom/client";
import "./load-css";
import App from "./app";

const SENTRY_DENY_URLS = [/maps\.googleapis\.com\//i, /chrome-extension/i];

const sentry = getFrontendSentry();
const sentryOptions: Sentry.BrowserOptions = {
  attachStacktrace: true,
  denyUrls: SENTRY_DENY_URLS,
  dsn: process.env.FRONTEND_SENTRY_DSN,
  enabled: isProduction(),
  integrations: [Sentry.browserSessionIntegration()],
  normalizeDepth: 0,
  release: frontendVersion,
};

sentry.init(sentryOptions);
const {hostname} = new URL(globalConfig.baseURL);

if (window.cordova) {
  // Our app uses an older native Cordova Sentry plugin.
  (sentry as any).configureScope((scope: any) => {
    scope.setTag("hostname", hostname);
    if ((window as any).APP_VERSION) {
      scope.setTag("appVersion", (window as any).APP_VERSION);
    }
  });
} else {
  const currentSentryScope = sentry.getCurrentScope();
  currentSentryScope.setTag("hostname", hostname);
}

if (isProduction()) {
  console.assert = (condition?: boolean, ...data: any[]): void => {
    if (!condition) {
      // eslint-disable-next-line no-console
      console.error(...data);
      sentry.captureMessage("console.assert", "error");
    }
  };
}

const manifest = window.localStorage && window.localStorage.getItem("manifest");
if (manifest) {
  localStorage.setItem("last_good_manifest", manifest);
}

(window as any).BOOTSTRAP_OK = true;
const isIpad = navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
bowser.tablet = bowser.tablet || isIpad;
bowser.mobile = !bowser.tablet && bowser.mobile;

const rootElement = document.getElementById("main");
if (!rootElement) {
  throw new Error("Could not find root element");
}

const root = createRoot(rootElement);
root.render(<App />);

import {HOUR_MILLISECONDS, isProduction} from "@co-common-libs/utils";
import {
  actions,
  getAuthenticated,
  getAuthenticationLoaded,
  getCommitLoaded,
  getCustomerSettings,
  getDeviceConfigLoaded,
  getOfflineLoaded,
} from "@co-frontend-libs/redux";
import {jsonFetchErrorEvent, StatusError} from "@co-frontend-libs/utils";
import {
  checkDoneSignal,
  checkStartedSignal,
  performUpdateCheck,
  updateReadySignal,
} from "app-utils";
import React, {useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {Loading} from "../pages/loading";
import {Login} from "../pages/login";
import {Authenticated} from "./authenticated";
import {FetchingProgress} from "./fetching-progress";

const UPDATE_CHECK_INTERVAL_HOURS = 2;

const HTTP_406_NOT_ACCEPTABLE = 406;

interface InitializedProps {
  children: React.ReactNode;
}

export function Initialized(props: InitializedProps): React.JSX.Element {
  const dispatch = useDispatch();

  const customerSettings = useSelector(getCustomerSettings);
  const offlineLoaded = useSelector(getOfflineLoaded);
  const commitLoaded = useSelector(getCommitLoaded);
  const authenticationLoaded = useSelector(getAuthenticationLoaded);
  const deviceConfigLoaded = useSelector(getDeviceConfigLoaded);
  const authenticated = useSelector(getAuthenticated);

  useEffect(() => {
    dispatch(actions.configLoad());
    dispatch(actions.loadSession());
    dispatch(actions.loadOffline());
    if (typeof cordova !== "undefined") {
      document.addEventListener("resume", performUpdateCheck, false);
    }
    const periodicUpdateCheckIntervalId = window.setInterval(
      performUpdateCheck,
      UPDATE_CHECK_INTERVAL_HOURS * HOUR_MILLISECONDS,
    );
    return () => {
      if (typeof cordova !== "undefined") {
        document.removeEventListener("resume", performUpdateCheck, false);
      }
      window.clearInterval(periodicUpdateCheckIntervalId);
    };
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = updateReadySignal.subscribe(() => {
      dispatch(actions.updateReady());
    });
    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = checkStartedSignal.subscribe(() => {
      dispatch(actions.checkingForUpdates(true));
    });
    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = checkDoneSignal.subscribe(() => {
      dispatch(actions.checkingForUpdates(false));
    });
    return unsubscribe;
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = jsonFetchErrorEvent.subscribe((error: unknown): void => {
      if (error instanceof StatusError && error.status === HTTP_406_NOT_ACCEPTABLE) {
        batch(() => {
          dispatch(actions.updateRequired());
          performUpdateCheck();
        });
      }
    });
    return unsubscribe;
  }, [dispatch]);

  const {companyName} = customerSettings;
  useEffect(() => {
    if (isProduction()) {
      document.title = companyName;
    } else {
      document.title = `DEV ${companyName}`;
    }
  }, [companyName]);

  useEffect(() => {
    if (offlineLoaded) {
      dispatch(actions.loadCommit());
    }
  }, [dispatch, offlineLoaded]);

  if (!offlineLoaded || !commitLoaded || !authenticationLoaded || !deviceConfigLoaded) {
    return <Loading />;
  }
  if (!authenticated) {
    return <Login />;
  }

  return (
    <>
      <FetchingProgress />
      <Authenticated>{React.Children.only(props.children)}</Authenticated>
    </>
  );
}

import {isDevelopment} from "./environment";

export function setUnion<T>(a: ReadonlySet<T>, b: ReadonlySet<T>): Set<T> {
  const result = new Set<T>();
  function addToResult(value: T, _value: T, _set: ReadonlySet<T>): void {
    result.add(value);
  }
  a.forEach(addToResult);
  b.forEach(addToResult);
  return result;
}

export function setIntersection<T>(a: ReadonlySet<T>, b: ReadonlySet<T>): Set<T> {
  console.assert(a instanceof Set);
  console.assert(b instanceof Set);
  const result = new Set<T>();
  function addToResultIfInOther(value: T, _value: T, _set: ReadonlySet<T>): void {
    if (b.has(value)) {
      result.add(value);
    }
  }
  a.forEach(addToResultIfInOther);
  return result;
}

export function setDifference<T>(base: ReadonlySet<T>, remove: ReadonlySet<T>): Set<T> {
  console.assert(base instanceof Set);
  console.assert(remove instanceof Set);
  const result = new Set<T>();
  function addToResultIfNotInOther(value: T, _value: T, _set: ReadonlySet<T>): void {
    if (!remove.has(value)) {
      result.add(value);
    }
  }
  base.forEach(addToResultIfNotInOther);
  return result;
}

export function setFilter<T>(set: ReadonlySet<T>, predicate: (value: T) => boolean): Set<T> {
  console.assert(set instanceof Set);
  const result = new Set<T>();
  function addToResultIfMatching(value: T, _value: T, _set: ReadonlySet<T>): void {
    if (predicate(value)) {
      result.add(value);
    }
  }
  set.forEach(addToResultIfMatching);
  return result;
}

export function setMap<T, U>(set: ReadonlySet<T>, mapper: (value: T) => U): Set<U> {
  console.assert(set instanceof Set);
  const result = new Set<U>();
  function addMappedToResult(value: T, _value: T, _set: ReadonlySet<T>): void {
    result.add(mapper(value));
  }
  set.forEach(addMappedToResult);
  return result;
}

let setSomeWarned = false;

export function setSome<T>(set: ReadonlySet<T>, predicate: (value: T) => boolean): boolean {
  console.assert(set instanceof Set);
  if (isDevelopment() && !setSomeWarned) {
    // eslint-disable-next-line no-console
    console.warn(
      "unlike Array.some(), setSome() is forced to iterate over all elements, even after finding a true result; try to use arrays when needing some()",
    );
    setSomeWarned = true;
  }
  let result = false;
  function checkEntry(value: T, _value: T, _set: ReadonlySet<T>): void {
    if (result) {
      return;
    }
    if (predicate(value)) {
      result = true;
    }
  }
  set.forEach(checkEntry);
  return result;
}

let setFindWarned = false;

export function setFind<T>(set: ReadonlySet<T>, predicate: (value: T) => boolean): T | undefined {
  console.assert(set instanceof Set);
  if (isDevelopment() && !setFindWarned) {
    // eslint-disable-next-line no-console
    console.warn(
      "unlike Array.find(), setFind() is forced to iterate over all elements, even after finding a result; try to use arrays when needing find()",
    );
    setFindWarned = true;
  }
  let result: T | undefined;
  let done = false;
  function checkEntry(value: T, _value: T, _set: ReadonlySet<T>): void {
    if (done) {
      return;
    }
    if (predicate(value)) {
      done = true;
      result = value;
    }
  }
  set.forEach(checkEntry);
  return result;
}

export function setAdd<T>(existing: ReadonlySet<T> | undefined, value: T): ReadonlySet<T> {
  if (existing) {
    const result = new Set(existing);
    result.add(value);
    return result;
  } else {
    return new Set([value]);
  }
}

export function setRemove<T>(existing: ReadonlySet<T>, value: T): ReadonlySet<T> {
  const result = new Set(existing);
  result.delete(value);
  return result;
}

export function setsSameMembers<T extends boolean | number | string | null | undefined>(
  a: ReadonlySet<T>,
  b: ReadonlySet<T>,
): boolean {
  console.assert(a instanceof Set);
  console.assert(b instanceof Set);
  if (a === b) {
    return true;
  }
  if (a.size !== b.size) {
    return false;
  }
  if (a.size === 0) {
    console.assert(b.size === 0);
    return true;
  }
  for (const entry of a) {
    if (!b.has(entry)) {
      return false;
    }
  }
  return true;
}

export function mapMap<V, K, M>(
  map: ReadonlyMap<K, V>,
  mapper: (value: V, key: K) => M,
): Map<K, M> {
  const result = new Map<K, M>();
  function addMappedToResult(value: V, key: K, _map: ReadonlyMap<K, V>): void {
    result.set(key, mapper(value, key));
  }
  map.forEach(addMappedToResult);
  return result;
}

export function mapFilter<V, K>(
  map: ReadonlyMap<K, V>,
  predicate: (value: V, key: K) => boolean,
): Map<K, V> {
  const result = new Map<K, V>();
  function addToResultIfMatching(value: V, key: K, _map: ReadonlyMap<K, V>): void {
    if (predicate(value, key)) {
      result.set(key, value);
    }
  }
  map.forEach(addToResultIfMatching);
  return result;
}

export function mapMapKeys<V, K, M>(
  map: ReadonlyMap<K, V>,
  mapper: (key: K, value: V) => M,
): Map<M, V> {
  const result = new Map<M, V>();
  function addMappedToResult(value: V, key: K, _map: ReadonlyMap<K, V>): void {
    result.set(mapper(key, value), value);
  }
  map.forEach(addMappedToResult);
  return result;
}

let mapSomeWarned = false;

/** @deprecated */
export function mapSome<V, K>(
  map: ReadonlyMap<K, V>,
  predicate: (value: V, key: K) => boolean,
): boolean {
  console.assert(map instanceof Map);
  if (isDevelopment() && !mapSomeWarned) {
    // eslint-disable-next-line no-console
    console.warn(
      "unlike Array.some(), mapSome() is forced to iterate over all elements, even after finding a true result; try to use arrays when needing some()",
    );
    mapSomeWarned = true;
  }
  let result = false;
  function checkEntry(value: V, key: K, _map: ReadonlyMap<K, V>): void {
    if (result) {
      return;
    }
    if (predicate(value, key)) {
      result = true;
    }
  }
  map.forEach(checkEntry);
  return result;
}

let mapEveryWarned = false;

/** @deprecated */
export function mapEvery<V, K>(
  map: ReadonlyMap<K, V>,
  predicate: (value: V, key: K) => boolean,
): boolean {
  console.assert(map instanceof Map);
  if (isDevelopment() && !mapEveryWarned) {
    // eslint-disable-next-line no-console
    console.warn(
      "unlike Array.every(), mapEvery() is forced to iterate over all elements, even after finding a false result; try to use arrays when needing every()",
    );
    mapEveryWarned = true;
  }
  let result = true;
  function checkEntry(value: V, key: K, _map: ReadonlyMap<K, V>): void {
    if (!result) {
      return;
    }
    if (!predicate(value, key)) {
      result = false;
    }
  }
  map.forEach(checkEntry);
  return result;
}

let mapFindWarned = false;

/** @deprecated */
export function mapFind<V, K>(
  map: ReadonlyMap<K, V>,
  predicate: (value: V, key: K) => boolean,
): V | undefined {
  console.assert(map instanceof Map);
  if (isDevelopment() && !mapFindWarned) {
    // eslint-disable-next-line no-console
    console.warn(
      "unlike Array.find(), mapFind() is forced to iterate over all elements, even after finding a result; try to use arrays when needing find()",
    );
    mapFindWarned = true;
  }
  let result: V | undefined;
  let done = false;
  function checkEntry(value: V, key: K, _map: ReadonlyMap<K, V>): void {
    if (done) {
      return;
    }
    if (predicate(value, key)) {
      done = true;
      result = value;
    }
  }
  map.forEach(checkEntry);
  return result;
}

export function makeMapFromArray<T, K>(entries: readonly T[], getKey: (entry: T) => K): Map<K, T> {
  const result = new Map<K, T>();
  for (let i = 0; i < entries.length; i += 1) {
    const entry = entries[i];
    const key = getKey(entry);
    result.set(key, entry);
  }
  return result;
}

export function convertResourceArrayToMap<T extends {url: string}>(
  array: readonly T[],
): ReadonlyMap<string, T> {
  const map = new Map<string, T>();
  array.forEach((entry) => {
    map.set(entry.url, entry);
  });
  return map;
}

export function makeMapFromObject<T>(obj: {[key: string]: T}): Map<string, T> {
  return new Map(Object.entries(obj));
}

export function makeOrderedMapFromObject<T>(obj: {[key: string]: T}): Map<string, T> {
  const result = new Map<string, T>();
  const keys = Object.keys(obj);
  keys.sort();
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = obj[key];
    result.set(key, value);
  }
  return result;
}

export function makeOrderedSetFromArray<T>(arr: readonly T[]): Set<T> {
  const keys = arr.slice();
  keys.sort();
  return new Set(keys);
}

export function makeObjectFromMap<T>(map: ReadonlyMap<string, T>): {
  [key: string]: T;
} {
  const result: {[key: string]: T} = {};
  map.forEach((value, key) => {
    result[key] = value;
  });
  return result;
}

export function mapSet<K, V>(state: ReadonlyMap<K, V>, key: K, value: V): ReadonlyMap<K, V> {
  const newState = new Map(state);
  newState.set(key, value);
  return newState;
}

export function mapUpdate<K, V>(
  state: ReadonlyMap<K, V>,
  key: K,
  updater: (value: V | undefined) => V,
): ReadonlyMap<K, V> {
  const newState = new Map(state);
  const oldValue = newState.get(key);
  const newValue = updater(oldValue);
  newState.set(key, newValue);
  return newState;
}

export function partition<T>(entries: readonly T[], predicate: (value: T) => boolean): [T[], T[]] {
  const truePart: T[] = [];
  const falsePart: T[] = [];
  for (let i = 0; i < entries.length; i += 1) {
    const entry = entries[i];
    if (predicate(entry)) {
      truePart.push(entry);
    } else {
      falsePart.push(entry);
    }
  }
  return [truePart, falsePart];
}

export function groupByToMap<T, K>(entries: readonly T[], getKey: (entry: T) => K): Map<K, T[]> {
  const result = new Map<K, T[]>();
  for (const entry of entries) {
    const key = getKey(entry);
    const existing = result.get(key);
    if (existing) {
      existing.push(entry);
    } else {
      result.set(key, [entry]);
    }
  }
  return result;
}

export function mapSetAdd<K, V>(map: Map<K, Set<V>>, key: K, value: V): void {
  const existing = map.get(key);
  if (existing) {
    existing.add(value);
  } else {
    map.set(key, new Set([value]));
  }
}

export function mapSetHas<K, V>(map: Map<K, Set<V>>, key: K, value: V): boolean {
  const set = map.get(key);
  return set !== undefined && set.has(value);
}

export function incrementMapValue<T>(map: Map<T, number>, key: T, value: number): void {
  map.set(key, (map.get(key) || 0) + value);
}

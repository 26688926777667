/**
 * This file was generated from reporting-specification.json.
 * Run ../../scripts/rebuild-schema.cjs to update.
 */

import { JSONSchema7 } from "json-schema";
import type { DeepReadonly } from "ts-essentials";

export type InputType =
  | "boolean"
  | "text"
  | "integer"
  | "decimal"
  | "choice"
  | "multiple_choice";
export type LogCountChoice = 0 | 1 | "*";

export interface ReportSpecification {
  active: boolean;
  allowSkip: boolean;
  allowTotalsTable: boolean;
  allowTransportedMaterialCountMismatch: boolean;
  autoInsertAmountInDelivery: boolean;
  autoInsertLastPickupValues: boolean;
  autoInsertLastWorkplaceValues?: boolean;
  customerLogSums: boolean | null;
  displayProductsPerWorkplace: boolean;
  extraFooterText: string;
  fieldsUsedFor: "unused" | "pickup" | "delivery" | "workplace";
  hideMaterialsOnPrintout: boolean;
  horizontal: boolean;
  id?: string;
  identifier: string;
  invoiceCustomerAddress: boolean;
  invoiceCustomerName: boolean;
  invoiceCustomerVatNumber: boolean;
  invoiceWorkplaceAddress: boolean;
  invoiceWorkplaceName: boolean;
  lastChanged?: string;
  name: string;
  reportTransport: boolean;
  requireAtLeastOneLogEntry: boolean;
  sendMailToPickupDeliveryCustomers: boolean;
  shared: boolean;
  showCustomerOnEntryTable: boolean;
  showLocationMaterialSums: boolean;
  showLocationProducts: boolean;
  showMachines: boolean;
  showSharedTotalsTableOnAllOrderTasks?: boolean;
  showTaskTimeOnReport: boolean;
  simpleLocationSumLog: boolean;
  taskData: {
    enterBaseDataLabel?: string;
    inputs?: ReportingTaskDataInputSpecification[];
  };
  url: string;
  versionNote: string;
  workplaceData: {
    delivery?: ReportingWorkplaceTypeDataSpecification;
    pickup?: ReportingWorkplaceTypeDataSpecification;
    workplace?: ReportingWorkplaceTypeDataSpecification;
  };
  workplaceRegistration: 0 | 1 | 2;
}
export interface ReportingTaskDataInputSpecification {
  average?: boolean;
  conversion?: ConversionSpecification;
  format: ReportingInputFormatSpecification;
  identifier: string;
  label: string;
  multiplication?: MultiplicationSpecification;
  readonly?: boolean;
  required: boolean;
  subtraction?: SubtractionSpecification;
  unit?: string;
}
export interface ConversionSpecification {
  denominator: string;
  numerator: string;
}
export interface ReportingInputFormatSpecification {
  alternatives?: ReportingChoiceAlternative[];
  booleanDefault?: boolean;
  choices?: ReportingChoiceAlternative[];
  decimalPlaces?: number;
  maxDigits?: number;
  multiline?: boolean;
  type: InputType;
}
export interface ReportingChoiceAlternative {
  identifier: string;
  label: string;
}
export interface MultiplicationSpecification {
  multiplicand: string;
  multiplier: string;
}
export interface SubtractionSpecification {
  minuend: string;
  subtrahend: string;
}
export interface ReportingWorkplaceTypeDataSpecification {
  calculators?: CalculatorSpecifications;
  inputs?: ReportingWorkplaceInputSpecification[];
  logEntries: LogCountChoice;
  logInputs?: ReportingLogInputSpecification[];
  logPriceItems?: boolean;
  logProducts?: boolean;
  priceItemConversion?: ConversionDenominatorSpecification;
  productConversion?: ConversionDenominatorSpecification;
  requireAtLeastOneProduct?: boolean;
}
export interface CalculatorSpecifications {
  [k: string]: CalculatorSpecification;
}
export interface CalculatorSpecification {
  inputLabelA: string;
  inputLabelB: string;
  operation: "-";
  resultLabel: string;
}
export interface ReportingWorkplaceInputSpecification {
  average?: boolean;
  conversion?: ConversionSpecification;
  fieldAreaTarget?: boolean;
  fieldCropTarget?: boolean;
  format: ReportingInputFormatSpecification;
  identifier: string;
  label: string;
  multiplication?: MultiplicationSpecification;
  readonly?: boolean;
  required: boolean;
  subtraction?: SubtractionSpecification;
  sum?: boolean;
  transfer?: boolean;
  unit?: string;
}
export interface ReportingLogInputSpecification {
  average?: boolean;
  conversion?: ConversionSpecification;
  copyValueFromFirstValidPickup?: string;
  copyValueFromLastDelivery?: string;
  fieldAreaTarget?: boolean;
  fieldCropTarget?: boolean;
  format: ReportingInputFormatSpecification;
  identifier: string;
  label: string;
  multiplication?: MultiplicationSpecification;
  readonly?: boolean;
  required: boolean;
  subtraction?: SubtractionSpecification;
  sum?: boolean;
  transfer?: boolean;
  transferToEntry?: boolean;
  unit?: string;
}
export interface ConversionDenominatorSpecification {
  field: string;
  label?: string;
  unit: string;
}

export const schema = {
  $schema: "http://json-schema.org/draft-07/schema",
  additionalProperties: false,
  definitions: {
    CalculatorSpecification: {
      additionalProperties: false,
      properties: {
        inputLabelA: { type: "string" },
        inputLabelB: { type: "string" },
        operation: { enum: ["-"] },
        resultLabel: { type: "string" },
      },
      required: ["inputLabelA", "inputLabelB", "operation", "resultLabel"],
      type: "object",
    },
    CalculatorSpecifications: {
      additionalProperties: { $ref: "#/definitions/CalculatorSpecification" },
      type: "object",
    },
    ConversionDenominatorSpecification: {
      additionalProperties: false,
      properties: {
        field: { type: "string" },
        label: { type: "string" },
        unit: { type: "string" },
      },
      required: ["field", "unit"],
      type: "object",
    },
    ConversionSpecification: {
      additionalProperties: false,
      properties: {
        denominator: { type: "string" },
        numerator: { type: "string" },
      },
      required: ["denominator", "numerator"],
      type: "object",
    },
    InputType: {
      enum: [
        "boolean",
        "text",
        "integer",
        "decimal",
        "choice",
        "multiple_choice",
      ],
    },
    LogCountChoice: { enum: [0, 1, "*"] },
    MultiplicationSpecification: {
      additionalProperties: false,
      properties: {
        multiplicand: { type: "string" },
        multiplier: { type: "string" },
      },
      required: ["multiplicand", "multiplier"],
      type: "object",
    },
    ReportingChoiceAlternative: {
      additionalProperties: false,
      properties: { identifier: { type: "string" }, label: { type: "string" } },
      required: ["identifier", "label"],
      type: "object",
    },
    ReportingInputFormatSpecification: {
      additionalProperties: false,
      properties: {
        alternatives: {
          items: { $ref: "#/definitions/ReportingChoiceAlternative" },
          type: "array",
        },
        booleanDefault: { type: "boolean" },
        choices: {
          items: { $ref: "#/definitions/ReportingChoiceAlternative" },
          type: "array",
        },
        decimalPlaces: { type: "number" },
        maxDigits: { type: "number" },
        multiline: { type: "boolean" },
        type: { $ref: "#/definitions/InputType" },
      },
      required: ["type"],
      type: "object",
    },
    ReportingLogInputSpecification: {
      additionalProperties: false,
      properties: {
        average: { type: "boolean" },
        conversion: { $ref: "#/definitions/ConversionSpecification" },
        copyValueFromFirstValidPickup: { type: "string" },
        copyValueFromLastDelivery: { type: "string" },
        fieldAreaTarget: { type: "boolean" },
        fieldCropTarget: { type: "boolean" },
        format: { $ref: "#/definitions/ReportingInputFormatSpecification" },
        identifier: { type: "string" },
        label: { type: "string" },
        multiplication: { $ref: "#/definitions/MultiplicationSpecification" },
        readonly: { type: "boolean" },
        required: { type: "boolean" },
        subtraction: { $ref: "#/definitions/SubtractionSpecification" },
        sum: { type: "boolean" },
        transfer: { type: "boolean" },
        transferToEntry: { type: "boolean" },
        unit: { type: "string" },
      },
      required: ["format", "identifier", "label", "required"],
      type: "object",
    },
    ReportingTaskDataInputSpecification: {
      additionalProperties: false,
      properties: {
        average: { type: "boolean" },
        conversion: { $ref: "#/definitions/ConversionSpecification" },
        format: { $ref: "#/definitions/ReportingInputFormatSpecification" },
        identifier: { type: "string" },
        label: { type: "string" },
        multiplication: { $ref: "#/definitions/MultiplicationSpecification" },
        readonly: { type: "boolean" },
        required: { type: "boolean" },
        subtraction: { $ref: "#/definitions/SubtractionSpecification" },
        unit: { type: "string" },
      },
      required: ["format", "identifier", "label", "required"],
      type: "object",
    },
    ReportingWorkplaceInputSpecification: {
      additionalProperties: false,
      properties: {
        average: { type: "boolean" },
        conversion: { $ref: "#/definitions/ConversionSpecification" },
        fieldAreaTarget: { type: "boolean" },
        fieldCropTarget: { type: "boolean" },
        format: { $ref: "#/definitions/ReportingInputFormatSpecification" },
        identifier: { type: "string" },
        label: { type: "string" },
        multiplication: { $ref: "#/definitions/MultiplicationSpecification" },
        readonly: { type: "boolean" },
        required: { type: "boolean" },
        subtraction: { $ref: "#/definitions/SubtractionSpecification" },
        sum: { type: "boolean" },
        transfer: { type: "boolean" },
        unit: { type: "string" },
      },
      required: ["format", "identifier", "label", "required"],
      type: "object",
    },
    ReportingWorkplaceTypeDataSpecification: {
      additionalProperties: false,
      properties: {
        calculators: { $ref: "#/definitions/CalculatorSpecifications" },
        inputs: {
          items: { $ref: "#/definitions/ReportingWorkplaceInputSpecification" },
          type: "array",
        },
        logEntries: { $ref: "#/definitions/LogCountChoice" },
        logInputs: {
          items: { $ref: "#/definitions/ReportingLogInputSpecification" },
          type: "array",
        },
        logPriceItems: { type: "boolean" },
        logProducts: { type: "boolean" },
        priceItemConversion: {
          $ref: "#/definitions/ConversionDenominatorSpecification",
        },
        productConversion: {
          $ref: "#/definitions/ConversionDenominatorSpecification",
        },
        requireAtLeastOneProduct: { type: "boolean" },
      },
      required: ["logEntries"],
      type: "object",
    },
    SubtractionSpecification: {
      additionalProperties: false,
      properties: {
        minuend: { type: "string" },
        subtrahend: { type: "string" },
      },
      required: ["minuend", "subtrahend"],
      type: "object",
    },
    TaskDataSpecification: {
      additionalProperties: false,
      properties: {
        enterBaseDataLabel: { type: "string" },
        inputs: {
          items: { $ref: "#/definitions/ReportingTaskDataInputSpecification" },
          type: "array",
        },
      },
      type: "object",
    },
    WorkplaceDataSpecification: {
      additionalProperties: false,
      properties: {
        delivery: {
          $ref: "#/definitions/ReportingWorkplaceTypeDataSpecification",
        },
        pickup: {
          $ref: "#/definitions/ReportingWorkplaceTypeDataSpecification",
        },
        workplace: {
          $ref: "#/definitions/ReportingWorkplaceTypeDataSpecification",
        },
      },
      type: "object",
    },
    WorkplaceRegistration: { enum: [0, 1, 2] },
  },
  properties: {
    active: { default: true, type: "boolean" },
    allowSkip: { default: false, type: "boolean" },
    allowTotalsTable: { default: true, type: "boolean" },
    allowTransportedMaterialCountMismatch: { default: true, type: "boolean" },
    autoInsertAmountInDelivery: { default: true, type: "boolean" },
    autoInsertLastPickupValues: { default: true, type: "boolean" },
    autoInsertLastWorkplaceValues: { default: false, type: "boolean" },
    customerLogSums: { default: true, type: ["boolean", "null"] },
    displayProductsPerWorkplace: { default: true, type: "boolean" },
    extraFooterText: { default: "", type: "string" },
    fieldsUsedFor: { enum: ["unused", "pickup", "delivery", "workplace"] },
    hideMaterialsOnPrintout: { default: false, type: "boolean" },
    horizontal: { default: false, type: "boolean" },
    id: { type: "string" },
    identifier: { type: "string" },
    invoiceCustomerAddress: { default: false, type: "boolean" },
    invoiceCustomerName: { default: false, type: "boolean" },
    invoiceCustomerVatNumber: { default: false, type: "boolean" },
    invoiceWorkplaceAddress: { default: false, type: "boolean" },
    invoiceWorkplaceName: { default: false, type: "boolean" },
    lastChanged: { type: "string" },
    name: { default: "", type: "string" },
    reportTransport: { default: false, type: "boolean" },
    requireAtLeastOneLogEntry: { default: false, type: "boolean" },
    sendMailToPickupDeliveryCustomers: { default: false, type: "boolean" },
    shared: { default: false, type: "boolean" },
    showCustomerOnEntryTable: { default: false, type: "boolean" },
    showLocationMaterialSums: { default: false, type: "boolean" },
    showLocationProducts: { default: false, type: "boolean" },
    showMachines: { default: false, type: "boolean" },
    showSharedTotalsTableOnAllOrderTasks: { default: false, type: "boolean" },
    showTaskTimeOnReport: { default: true, type: "boolean" },
    simpleLocationSumLog: { default: false, type: "boolean" },
    taskData: { $ref: "#/definitions/TaskDataSpecification", default: {} },
    url: { type: "string" },
    versionNote: { default: "", type: "string" },
    workplaceData: {
      $ref: "#/definitions/WorkplaceDataSpecification",
      default: {},
    },
    workplaceRegistration: {
      $ref: "#/definitions/WorkplaceRegistration",
      default: 0,
    },
  },
  required: [
    "url",
    "active",
    "allowSkip",
    "allowTotalsTable",
    "allowTransportedMaterialCountMismatch",
    "autoInsertAmountInDelivery",
    "autoInsertLastPickupValues",
    "customerLogSums",
    "displayProductsPerWorkplace",
    "extraFooterText",
    "fieldsUsedFor",
    "hideMaterialsOnPrintout",
    "horizontal",
    "identifier",
    "invoiceCustomerAddress",
    "invoiceCustomerName",
    "invoiceCustomerVatNumber",
    "invoiceWorkplaceAddress",
    "invoiceWorkplaceName",
    "name",
    "reportTransport",
    "requireAtLeastOneLogEntry",
    "sendMailToPickupDeliveryCustomers",
    "shared",
    "showCustomerOnEntryTable",
    "showLocationMaterialSums",
    "showLocationProducts",
    "showMachines",
    "showTaskTimeOnReport",
    "simpleLocationSumLog",
    "taskData",
    "versionNote",
    "workplaceData",
    "workplaceRegistration",
  ],
  title: "ReportSpecification",
  type: "object",
} as const;

// just to validate that the type of schema matches JSONSchema7
export const schemaAsJSONSchemaType: DeepReadonly<JSONSchema7> = schema;
